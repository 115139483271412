import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Drawer from '../../Drawer/drawer';
import SupportedCountriesAccordion from '../SupportedCountriesAccordion/SupportedCountriesAccordion';
import type {
  CountrySelection,
  CountrySelectorCountry,
  CountrySelectorRegion,
} from '../../../types/CountrySelectorTypes';
import {
  getSelectedCountry,
  getSelectedLanguage,
  pickLanguageForCountry,
} from '../../../utils/countrySelectorHelper';
import { UnstyledButton } from '../../ButtonV2/Button';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { ScrollArea } from '../../ScrollArea/ScrollArea';

const selectedValuesStyles = 'text-base font-medium leading-5 tracking-tight';

type CountrySelectorMenuView = 'country' | 'language';

interface CountrySelectorMenuProps {
  isOpen: boolean;
  onSelect: (countrySelection: CountrySelection) => void;
  previousSelection: CountrySelection;
  regions: CountrySelectorRegion[];
  countrySelectorTitle: string;
  languageSelectorTitle: string;
  changeLanguageText: string;
  onToggle: () => void;
}

export default function CountrySelectorMenu({
  isOpen,
  onSelect,
  previousSelection,
  regions,
  countrySelectorTitle,
  languageSelectorTitle,
  changeLanguageText,
  onToggle,
}: CountrySelectorMenuProps): JSX.Element {
  const previousSelectedCountry = getSelectedCountry(
    regions,
    previousSelection.countryCode,
  );
  const [selectedCountry, setSelectedCountry] = useState(
    previousSelectedCountry,
  );
  const [view, setView] = useState<CountrySelectorMenuView>('country');
  const title = {
    country: countrySelectorTitle,
    language: languageSelectorTitle,
  };

  useEffect(() => {
    setSelectedCountry(previousSelectedCountry);
  }, [previousSelection]);

  const toggleView = (view: CountrySelectorMenuView): void => {
    setView(view);
  };

  const handleOnSelect = (countrySelection: CountrySelection): void => {
    onSelect(countrySelection);
    setView('country');
  };

  const handleCountrySelection = (country: CountrySelectorCountry): void => {
    handleOnSelect({
      countryCode: country.countryCode,
      languageCode: pickLanguageForCountry(country, [...navigator.languages]),
    });
  };

  const handleBackClick = (): void => {
    setSelectedCountry(previousSelectedCountry);
    toggleView('country');
  };

  const handleSetIsOpen = (): void => {
    if (view !== 'country') {
      setSelectedCountry(previousSelectedCountry);
      toggleView('country');
    }
    onToggle();
  };

  return (
    <Drawer
      isOpen={isOpen}
      onBackClick={view === 'language' ? handleBackClick : undefined}
      setIsOpen={handleSetIsOpen}
      title={title[view]}
    >
      <ScrollArea className='max-h-full w-full sm:w-[535px]'>
        <div className='relative w-full overflow-x-hidden'>
          <div
            className={clsx(
              'flex h-full w-full flex-col items-start gap-5 transition-transform',
              view === 'country' ? 'translate-x-0' : '-translate-x-full',
            )}
          >
            <div className='w-fit min-w-[171px] rounded-xl border border-black p-3'>
              <h3 className={selectedValuesStyles}>
                {selectedCountry.displayName}
              </h3>
              <p className={clsx(selectedValuesStyles, 'text-black/50')}>
                {
                  getSelectedLanguage(
                    previousSelectedCountry,
                    previousSelection,
                  ).displayName
                }
              </p>
            </div>
            {selectedCountry.languages.length > 1 ? (
              <UnstyledButton
                className='text-blue-strain font-regular px-3 text-base leading-5 tracking-tight underline'
                onClick={() => toggleView('language')}
              >
                {changeLanguageText}
              </UnstyledButton>
            ) : null}
            {isOpen ? (
              <SupportedCountriesAccordion
                onSelect={handleCountrySelection}
                regions={regions}
              />
            ) : null}
          </div>
          <div
            className={clsx(
              'absolute right-0 top-0 h-full w-full transition-transform',
              view === 'language' ? 'translate-x-0' : 'translate-x-full',
            )}
          >
            <LanguageSelector
              country={selectedCountry}
              onSelect={handleOnSelect}
            />
          </div>
        </div>
      </ScrollArea>
    </Drawer>
  );
}
