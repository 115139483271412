import React from 'react';

export interface PromoBannerProps {
  content: string;
}

export const PromoBanner = ({
  content,
}: PromoBannerProps): JSX.Element | null => {
  return (
    <div
      className='animate-accordion-down flex h-16 w-full items-center justify-center bg-black px-4 py-2 text-center text-sm text-white transition-all sm:text-lg md:text-xl'
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};
